import axios from 'axios';
import log from 'shared/util/log';

// Current version of this app (client-side)
export const currentVersion = process.env.REACT_APP_BUILD_VERSION || 'Snapshot';
// Local storage key for last refresh time
const lastRefreshTimeKey = 'last-refresh-time';
// Refresh threshold in milliseconds
// In case something goes wrong - don't get stuck in an endless reload loop
const refreshThresholdMs = 30 * 1000;

const api = axios.create();

const getLastRefreshTime = () => {
  const lastRefreshTime = localStorage.getItem(lastRefreshTimeKey);
  if (lastRefreshTime === null) {
    return null;
  }
  try {
    return new Date(JSON.parse(lastRefreshTime));
  } catch (e) {
    return null;
  }
};

const setLastRefreshTimeAsNow = () => {
  const now = new Date();
  localStorage.setItem(lastRefreshTimeKey, JSON.stringify(now));
};

const shouldRefresh = () => {
  const lastRefreshTime = getLastRefreshTime();
  const now = new Date();
  return lastRefreshTime === null || now - lastRefreshTime >= refreshThresholdMs;
};

export const checkForNewVersion = async () => {
  const { data } = await api.get(`/version.json?cacheControl=${new Date().getTime()}`);
  const remoteVersion = data.version;
  log.info(`Checking build versions. Current version: ${currentVersion}, remote version: ${remoteVersion}`);
  if (currentVersion !== remoteVersion && shouldRefresh()) {
    setLastRefreshTimeAsNow();
    log.info('Refreshing the page due to build version mismatch');
    window.location.reload(true);
  }
};
