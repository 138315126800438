export const AttachmentType = {
  PURCHASE_AGREEMENT: 'PURCHASE_AGREEMENT',
  DELIVERY_ACT: 'DELIVERY_ACT',
  LEASING_AGREEMENT: 'AGREEMENT',
  BUYBACK_AGREEMENT: 'BUYBACK_AGREEMENT',
  INVOICE: 'INVOICE',
  INSURANCE: 'INSURANCE',
  OTHER: 'OTHER',

  // LV specific attachment types
  EUROPEAN_INFORMATION_FORM: 'EUROPEAN_INFORMATION_FORM',
  PROCUREMENT_ACT: 'PROCUREMENT_ACT',
  SANCTIONS_COMPLIANCE_APPLICATION: 'SANCTIONS_COMPLIANCE_APPLICATION',
  MUTUAL_SETTLEMENT_ACT: 'MUTUAL_SETTLEMENT_ACT',
  INSURANCE_PROXY: 'INSURANCE_PROXY',
  PERSONAL_DATA_SUBMISSION: 'PERSONAL_DATA_SUBMISSION',

  /**
   * Other attachments
   */
  // PROD LT
  DEALER_PACK: 'DEALER_PACK',
  // PROD LT, EE
  PAYMENT_SCHEDULE: 'PAYMENT_SCHEDULE',
  // PROD LT, EE, LV
  COMMERCIAL_OFFER: 'COMMERCIAL_OFFER',
  // PROD LT, EE
  CUSTOMER_OFFER: 'CUSTOMER_OFFER',
  // PROD LT
  BUSINESS_OFFER_CONSENT: 'BUSINESS_OFFER_CONSENT',
  // PROD LT
  ID_DOCUMENT_COPY: 'ID_DOCUMENT_COPY',
  // PROD LT, EE
  ADDITIONAL_DOCUMENTS: 'ADDITIONAL_DOCUMENTS',
  // PROD LT
  SIGNED_APPLICATION: 'SIGNED_APPLICATION',
  // PROD LT
  DUPLICATED_AGREEMENT: 'DUPLICATED_AGREEMENT',

  DEALER_OFFER: 'DEALER_OFFER',
  FINANCIAL_CONSUMER_INFO: 'FINANCIAL_CONSUMER_INFO',
  OPERATE_CONSUMER_INFO: 'OPERATE_CONSUMER_INFO',
  ADVANCED_PAYMENT_INVOICE: 'ADVANCED_PAYMENT_INVOICE',
  COMMENT_ATTACHMENT: 'COMMENT_ATTACHMENT',
};

export const LV_ATTACHMENT_TYPES_ORDER = [
  AttachmentType.EUROPEAN_INFORMATION_FORM,
  AttachmentType.LEASING_AGREEMENT,
  AttachmentType.PURCHASE_AGREEMENT,
  AttachmentType.DELIVERY_ACT,
  AttachmentType.PROCUREMENT_ACT,
  AttachmentType.INVOICE,
  AttachmentType.MUTUAL_SETTLEMENT_ACT,
  AttachmentType.INSURANCE_PROXY,
  AttachmentType.PERSONAL_DATA_SUBMISSION,
  AttachmentType.OTHER,
];

export const ATTACHMENT_TYPES_TO_FILTER = {
  PURCHASE_AGREEMENT: 'PURCHASE_AGREEMENT',
  DELIVERY_ACT: 'DELIVERY_ACT',
  LEASING_AGREEMENT: 'AGREEMENT',
  BUYBACK_AGREEMENT: 'BUYBACK_AGREEMENT',
  OTHER: 'OTHER',
};
